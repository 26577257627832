* {
 margin: 0;
 padding: 0;
 outline: 0;
 border: 0;
 list-style: none;
 box-sizing: border-box;
 text-decoration: none;
}

